<template>
  <div class="tabs-mf bg-white tab-payment">
    <b-tabs>
      <b-tab
        :active="tab === 'balance_allocation'"
        @click="handleChangeTab({tab: 'balance_allocation'})"
      >
        <template #title>
          <span class="tab-title font-14">{{ $t('payment.textBalanceAllocation') }}</span>
        </template>

        <balance-allowcation />
      </b-tab>
      <b-tab
        :active="tab === 'account_history'"
        @click="handleChangeTab({tab: 'account_history'})"
      >
        <template #title>
          <span class="tab-title">{{ $t('payment.textAdAccountTransactionHistory') }}</span>
        </template>
        <ads-account-history />
      </b-tab>
      <b-tab
        :active="tab === 'payment_history'"
        @click="handleChangeTab({tab: 'payment_history'})"
      >
        <template #title>
          <span class="tab-title">{{ $t('payment.textBalanceTransactionHistory',getPlatform) }}</span>
        </template>
        <business-account-history />
      </b-tab>
      <b-tab
        v-if="refundAutoMode"
        :active="tab === 'refund_history'"
        @click="handleChangeTab({tab: 'refund_history'})"
      >
        <template #title>
          <span class="tab-title">{{ $t('payment.autoRefundFeature.txtRefundHistory') }}</span>
        </template>
        <tab-history-refund />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import BalanceAllowcation from './BalanceAllowcation.vue'
import AdsAccountHistory from './AdsAccountHistory.vue'
import BusinessAccountHistory from './BusinessAccountHistory.vue'
import TabHistoryRefund from './TabHistoryRefund.vue'

export default {
  components: {
    BusinessAccountHistory,
    AdsAccountHistory,
    BalanceAllowcation,
    BTabs,
    BTab,
    TabHistoryRefund,
  },

  mixins: [envMixin],

  data() {
    return {
      tab: this.$route.query.tab || 'balance_allocation',
    }
  },
  computed: {
    getPlatform() {
      switch (this.platformName) {
        case 'SBH Media':
          return { platformName: '' }
        default:
          return { platformName: this.platformName }
      }
    },
  },

  watch: {
    $route: {
      handler(route) {
        if (route?.query?.tab) {
          this.tab = route?.query?.tab
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.tab = this.$route.query.tab || 'balance_allocation'
  },

  methods: {
    handleChangeTab({ tab }) {
      this.$router.push({ name: 'payment', query: { tab } })
    },
  },
}
</script>
<style lang="scss" scoped>
.tab-payment {
  border-radius: var(--border-radius-lg);

  .tab-title{
    font-size: 15px;
  }
}
</style>

<style lang="scss">
.tab-payment {
  .nav-tabs {
    margin-bottom: 24px;
    padding: 16px 16px 0;
    .nav-link{
      margin-right: 20px;
    }
  }
}
</style>
