<template>
  <b-modal
    ref="handle-balance-account-modal"
    id="modal-child"
    class="modal-child"
    ok-title="Confirm"
    size="w-add-fund"
    hide-footer
    hide-header
    hide-header-close
    centered
  >
    <!--Content-->
    <div class="d-flex justify-content-between top-modal">
      <h3
        data-testid="modal-child-title"
        class="font-medium text-primary"
      >
        {{
          openAddFundModal
            ? `${$t("payment.textTransferMoneyFrom")} ${$t(
              "stepGuideControl.balancePlatform",
              getPlatform
            )} ${$t("payment.textToAdAccountBalance")}`
            : $t("payment.textRemoveBalance")
        }}
      </h3>
      <div>
        <feather-icon
          data-testid="close-icon"
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <!-- add balance -->
    <b-row class="pb-2 mx-0 content-top">
      <b-col
        md="12"
        cols="12"
        class="px-0"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
            data-testid="text-ad-account-name-child"
            class="pr-0"
          >
            {{ $t("payment.textAdAccountName") }}
          </b-col>
          <b-col
            cols="12"
            md="8"
            data-testid="ad-account-name-child"
            class="text-secondary font-medium"
          >
            {{ name }}
          </b-col>
        </b-row>
        <b-row class="mx-div">
          <b-col
            cols="12"
            md="4"
            data-testid="text-ad-account-id-child"
          >
            {{ $t("payment.textAdAccountID") }}
          </b-col>
          <b-col
            cols="12"
            md="8"
            data-testid="ad-account-id-child"
            class="text-secondary font-medium"
          >
            {{ accountId }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div class="d-flex flex-wrap">
      <div :style="{ width: $device.mobile ? '100%' : '214px' }">
        <validation-observer
          v-if="openAddFundModal"
          ref="amountValidation"
        >
          <div>
            <b-form>
              <b-form-group>
                <label
                  for="amountAdjust"
                  data-testid="transfer-amount-label"
                  class="form-label"
                >
                  {{ $t("payment.labelTransferAmount") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  id="amountAdjust"
                  :rules="{
                    required,
                    amountNumberVN,
                    integerNumber,
                    rangeAutoTopUpAmount: {
                      min: minTopUpAdsAccount,
                      max: maxTopUpAdsAccount
                    }
                  }"
                  :name="$t('SMB.payment.amount')"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.number="amountAdjust"
                      data-testid="amount-adjust"
                      class="input-height"
                      placeholder="0"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      @keyup="totalAvailableBalance"
                      @keydown="onlyNumber"
                    />
                    <b-input-group-append is-text>
                      <img
                        data-testid="max-img"
                        class="cursor-pointer"
                        :src="
                          require('@/assets/images/pages/payment/ic-max.svg')
                        "
                        alt="image"
                        @click="depositAmount"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    data-testid="text-error-transfer-amount"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <small
                    v-if="!errors[0] && amountAdjust"
                    data-testid="text-suitable-transfer-amount"
                    class="d-flex align-items-center"
                    style="margin-top: 10px"
                  >
                    <b-img
                      class="icons-tick"
                      fluid
                      :src="require('@/assets/images/icons/icon-tick.svg')"
                      style="margin-right: 6px"
                      alt="guide"
                    />
                    {{ $t("payment.textThisAmountIsSuitable") }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </div>
        </validation-observer>
        <validation-observer
          v-else
          ref="amountValidation"
        >
          <div>
            <b-form>
              <b-form-group>
                <label
                  for="amount"
                  data-testid="transfer-amount-label"
                  class="form-label"
                >
                  {{ $t("payment.labelTransferAmount") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  id="amount"
                  :rules="{
                    required,
                    rangeAutoTopUpAmount: {
                      min: minWithdrawAdsAccount,
                      max: maxWithdrawAdsAccount
                    }
                  }"
                  :name="$t('SMB.payment.amount')"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="amountRemoveBalance"
                      data-testid="amount-adjust"
                      class="input-height"
                      :disabled="amountFormat(amountConverted) === 0"
                      placeholder="0"
                      :state="errors.length > 0 ? false : null"
                      @keyup="removeAvailableBalance"
                      @keydown="numberDecimal"
                    />
                    <b-input-group-append is-text>
                      <img
                        data-testid="max-img"
                        class="cursor-pointer"
                        :src="
                          require('@/assets/images/pages/payment/ic-max.svg')
                        "
                        alt="image"
                        @click="withdrawAmount"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    data-testid="text-error-transfer-amount"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <small
                    v-if="!errors[0] && amountRemoveBalance"
                    data-testid="text-suitable-transfer-amount"
                    class="d-flex align-items-center"
                    style="margin-top: 10px"
                  >
                    <b-img
                      class="icons-tick"
                      fluid
                      :src="require('@/assets/images/icons/icon-tick.svg')"
                      style="margin-right: 6px"
                      alt="guide"
                    />
                    {{ $t("payment.textThisAmountIsSuitable") }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </div>
        </validation-observer>
      </div>
      <div class="icon-center w-100 w-md-auto">
        <div
          v-if="$device.mobile"
          class="icon-arrow"
          :class="openAddFundModal ? 'icon-green' : 'icon-red'"
        >
          <feather-icon
            :icon="openAddFundModal ? 'ArrowDownIcon' : 'ArrowUpIcon'"
            size="14"
          />
        </div>
        <div
          v-else
          class="icon-arrow"
          :class="openAddFundModal ? 'icon-green' : 'icon-red'"
        >
          <feather-icon
            :icon="openAddFundModal ? 'ArrowRightIcon' : 'ArrowLeftIcon'"
            size="14"
          />
        </div>
      </div>
      <div :style="{ width: $device.mobile ? '100%' : '214px' }">
        <b-form class="available-form">
          <b-form-group>
            <label
              for="availableBalance"
              data-testid="total-balance-ad-account-label"
              class="form-label"
            >
              {{ $t("payment.textTotalBalance") }}
            </label>
            <b-input-group class="input-group-merge width-custom w-100">
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="amountAccountBalance"
                id="availableBalance"
                data-testid="available-balance"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-form>
      </div>
    </div>

    <div
      class="text-secondary d-flex align-items-baseline balance-txt flex-wrap"
    >
      <span
        data-testid="text-balance"
        class="font-weight-bolder"
      >{{ $t("account.balance", { platform: platformName }) }}:</span>
      <b-spinner
        v-if="loading"
        variant="secondary"
        small
        style="margin-left: 10px"
      />
      <h4
        v-else
        data-testid="balance-wallet"
        class="ml-md-1 total-money text-green"
      >
        ${{ userBalance.toLocaleString("en-US") }}
        <span
          data-testid="currency-balance"
          class="type-money text-primary"
        >USD
        </span>
        <img
          v-b-tooltip.hover.top.v-light="
            !$device.mobile && !$device.ipad
              ? $t('stepGuideControl.textAddFund')
              : ''
          "
          id="trigger-add-balance-ecomdy-home"
          data-testid="trigger-add-balance-ecomdy-home"
          :src="isTikTokAppCenter ? require('@/assets/images/common/ic-add-fund-red.svg') : require('@/assets/images/common/ic-add-fund-yellow.svg')"
          alt="image"
          class="cursor-pointer ml-1"
          @click="handleAddFund"
        >
      </h4>
    </div>

    <!-- modal footer -->
    <div class="d-flex justify-content-end mt-2">
      <btn-loading
        id="cancel-submit-add-fund-to-ads"
        data-testid="cancel-submit-add-fund-to-ads"
        variant-convert="flat-secondary"
        pill
        @click="hideModal"
      >
        {{ $t('common.btnCancel') }}
      </btn-loading>
      <btn-loading
        id="submit-add-fund-to-ads"
        data-testid="submit-add-fund-to-ads"
        span-id="submit-add-fund-to-ads"
        class="ml-1"
        variant-convert="btn-submit"
        type="submit"
        pill
        :loading="loading"
        @click.prevent="submitForm"
      >
        {{ $t("common.btnApply") }}
      </btn-loading>
    </div>
    <!-- modal footer -->
    <!-- add balance -->
  </b-modal>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BModal,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  greaterThanZero,
  greaterThanTen,
  integerNumber,
  rangeAutoTopUpAmount,
  amountNumberVN,
} from '@validations'
import { toastification } from '@core/mixins/toast'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import { bus } from '@/plugins/bus'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import validateMixin from '@/mixins/validateMixin'
import paymentMixin from '@/mixins/paymentMixin'
import NP from 'number-precision'
import authMixin from '@/mixins/authMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BSpinner,
    // component
    BtnLoading,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    numberFormatMixin,
    toastification,
    generalConfigsMixin,
    billingMixin,
    envMixin,
    stepGuideMixin,
    validateMixin,
    paymentMixin,
    authMixin,
  ],
  props: {
    openAddFundModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    account: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      amountAccountBalance: 0,
      amountAdjust: null,
      amountRemoveBalance: null,

      // validate
      required,
      greaterThanZero,
      greaterThanTen,
      integerNumber,
      rangeAutoTopUpAmount,
      amountNumberVN,
    }
  },
  computed: {
    ...mapGetters(['loading', 'message', 'status']),
    ...mapGettersAuth(['isTikTokAppCenter']),
    name() {
      return this.account.name || ''
    },

    userBalance() {
      const amount = _get(this.userInfo, ['data', 'balance'], 0)
      return this.amountFormat(amount)
    },

    userId() {
      return this.userInfo?._id || ''
    },

    accountId() {
      return this.account._id || ''
    },

    userInfoLoading() {
      return _get(this.userInfo, ['loading'], false)
    },

    amountConverted() {
      const { currency, balance } = this.account
      const rate = this.USDToVNDRate
      return currency === 'VND' ? balance / rate : balance
    },

    isMaxAmountAdjust() {
      const balanceUser = _get(this.userInfo, ['data', 'balance'], 0)
      return this.amountFormat(balanceUser) > 0
        ? this.amountAdjust === this.amountFormat(balanceUser)
        : false
    },

    isMaxRemoveBalance() {
      return this.amountConverted > 0
        ? this.amountRemoveBalance === this.amountFormat(this.amountConverted)
        : false
    },

    getPlatform() {
      switch (this.platformName) {
        case 'SBH Media':
          return { platform: '' }
        default:
          return { platform: this.platformName }
      }
    },
  },

  watch: {
    account: {
      handler() {
        this.availableBalanceNew()
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['createAdsRecharge']),
    ...mapActionsAuth(['getAccountInfo']),

    hideModal() {
      this.$refs['handle-balance-account-modal'].hide()
      if (this.openAddFundModal) {
        this.resetFormAddBalance()
      } else this.resetFormRemoveBalance()
    },

    showModal() {
      this.$refs['handle-balance-account-modal'].show()
    },

    resetFormAddBalance() {
      this.amountAdjust = null
      this.availableBalanceNew()
    },

    resetFormRemoveBalance() {
      this.amountRemoveBalance = null
      this.availableBalanceNew()
    },

    availableBalanceNew() {
      this.amountAccountBalance = this.amountConverted
    },

    totalAvailableBalance() {
      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })
      const amount = this.userInfo.data.balance

      if (this.amountAdjust >= 0) {
        if (this.amountAdjust > amount) {
          this.amountAdjust = Math.floor(amount)
        }
        this.amountAccountBalance = this.amountFormat(
          this.amountConverted + this.amountAdjust,
        )
      }
    },

    depositAmount() {
      const amount = _get(this.userInfo, ['data', 'balance'], 0)
      this.amountAdjust = Math.floor(amount)
      this.totalAvailableBalance()
    },

    removeAvailableBalance() {
      // Xác thực giá trị đầu vào sau khi DOM cập nhật
      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })

      // Kiểm tra nếu giá trị amountRemoveBalance hợp lệ
      if (this.amountRemoveBalance < 0) return

      // Kiểm tra và điều chỉnh nếu amountRemoveBalance lớn hơn amountConverted
      if (this.amountRemoveBalance > this.amountConverted) {
        this.amountRemoveBalance = this.amountFormat(this.amountConverted)
      }

      // Tính toán amountAccountBalance và làm tròn đến hai chữ số thập phân
      this.amountAccountBalance = NP.minus(
        this.amountConverted,
        this.amountRemoveBalance,
      ).toFixed(2)

      const amountRemoveBalanceStr = String(this.amountRemoveBalance)
      const decimalIndex = amountRemoveBalanceStr.indexOf('.')

      if (decimalIndex !== -1 && amountRemoveBalanceStr.length - decimalIndex > 2) {
        this.amountRemoveBalance = parseFloat(this.amountRemoveBalance).toFixed(2)
      }
    },

    withdrawAmount() {
      this.amountRemoveBalance = this.amountConverted
      this.removeAvailableBalance()
    },

    async submitForm() {
      if (this.noPlan && this.openAddFundModal) {
        this.$router.push('/billing')
      } else {
        const step = localStorage.getItem('pancakeUserStep')
        const success = await this.$refs.amountValidation.validate()
        const type = this.openAddFundModal ? 'RECHARGE' : 'REFUND'
        const amount = this.openAddFundModal
          ? this.amountAdjust
          : this.amountRemoveBalance

        if (success) {
          const params = {
            id: this.accountId,
            type,
            amount,
          }
          await this.createAdsRecharge(params)
          if (this.status) {
            this.toastSuccess(
              this.openAddFundModal
                ? this.$t('balance.addFundsSuccess')
                : this.$t('balance.withdrawSuccess'),
            )
            this.$emit('fetch-ads-accounts')
            this.$emit('hide-modal')
            bus.$emit('refresh-ads-table-history')
            bus.$emit('refresh-balance-allocation-table')
            this.getAccountInfo()
            if (this.hasStepGuide) {
              window.location.href = '/my-ads-account'
            }
            this.hideModal()

            if (this.isPancakeUser && step === '4') {
              localStorage.removeItem('pancakeUserStep')
              setTimeout(() => {
                this.navigateToPancake()
              }, 2000)
            }
          } else {
            this.toastFailure(this.message)
          }
        }
      }
    },

    handleAddFund() {
      if (this.isTikTokAppCenter) {
        this.goToAddFundFromTikTok()
      } else {
        this.goToAddFund()
      }
    },

    goToAddFund() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else if (this.hasPaypal) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

    goToAddFundFromTikTok() {
      const idToken = localStorage.getItem('id_token')
      if (this.noPlan) {
        window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
      } else if (this.isOnlyEcomdyPlatform) {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?tab=paypal&id_token=${idToken}`)
      } else {
        window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?id_token=${idToken}`)
      }
    },
  },
}
</script>
<style lang="scss">
.modal-w-add-fund {
  max-width: 556px;
  margin-top: 55px;
}

.modal-w-withdraw {
  max-width: 734px;
  margin-top: 55px;
}

#modal-child {
  .modal-content {
    border-radius: 32px !important;
  }

  .modal-footer-custom {
    border-top: none !important;
  }

  .modal-body {
    @media (min-width: 767px) {
      padding: 2rem !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.top-modal {
  margin-bottom: 30px;
}

.content-top {
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  margin-bottom: 24px;
}

.input-amountUserBalance {
  @media (min-width: 767px) {
    // justify-content: center;
  }
}

.input-amountAdjust {
  padding-left: 0;

  @media (max-width: 767px) {
    padding-left: 1rem;
  }
}

.input-accountBalance {
  padding-right: 0;

  @media (max-width: 767px) {
    padding-right: 1rem;
  }
}

.icon-center {
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .icon-arrow {
    width: max-content;
    height: max-content;
  }

  .icon-green {
    color: #1db9c3;
  }

  .icon-red {
    color: #e11c4e;
  }

  @media (min-width: 767px) {
    max-width: max-content;
    height: 100%;
    position: relative;
    margin: 0 2rem;

    .icon-arrow {
      position: absolute;
      top: 2.4rem;
    }
  }
}

.total-money {
  .type-money {
    font-weight: normal;
    font-size: 16px;
  }
}

.input-height {
  height: 48px;
}

.cash-box {
  background: #fafafa;
  border-radius: 16px;
}

.text-secondary {
  color: #3d405b !important;
}

.mx-div {
  margin-top: 12px;
  margin-bottom: 12px;
}

.account-info-box {
  background: #fafafa;

  span {
    font-size: 2rem;
  }
}

.available-fund {
  border: 1px solid #e0e0e5;
  border-radius: 14px;
  padding: 0.5rem 1rem;
  word-wrap: break-word;
}

.divider {
  height: 70%;
  width: 1px;
  background: #e0e0e5;
}

.balance-account {
  background-color: #ffffff;
  border-radius: 14px;
}

.balance-txt {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.available-form {
  .input-group-text {
    background-color: #efefef;
  }
}
</style>
