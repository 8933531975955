<template>
  <div>
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop ads-account-history">
        <!-- filter 1 -->
        <b-row class="d-flex justify-content-end input-control my-2">
          <b-col
            md="4"
            lg="3"
            class="mt-1 mt-md-0"
          >
            <div class="status-input">
              <v-select
                v-model="statusSelected"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col
            md="5"
            class="input-height pl-md-0 mt-1 mt-md-0"
          >
            <calendar
              v-model="rangeDate"
              :placeholder="placeholder"
              @input="handleDateChange"
            />
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>

    <!-- table -->
    <vue-good-table
      class="vgt-no-border"
      mode="remote"
      :columns="columns"
      :rows="histories"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: total > 0,
      }"
      :sort-options="{
        enabled: total > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'adsAccountId'"
          class="d-flex align-items-center"
        >
          <b-img
            class="ads-avt-img"
            :src="adsAccountImg"
          />
          <div class="d-flex flex-column">
            <b-link
              class="underline text-primary font-weight-600"
              href="https://ads.tiktok.com/i18n/home"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ props.row.adsAccountId.name || '' }}
            </b-link>
            <span class="text-placeholder font-14">ID: {{ props.row.adsAccountId._id || '' }}</span>
          </div>
        </div>

        <!-- Column: Ad Account -->
        <div
          v-else-if="props.column.field === 'createdAt'"
          class="d-flex flex-column"
        >
          <span>{{ createdDate(props.row.createdAt ) }}</span>
        </div>

        <!-- Column: transaction type -->
        <span
          v-else-if="props.column.field === 'type'"
          class="text-nowrap text-capitalize"
        >
          {{ transactionTxt(props.row.type) }}
        </span>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="text-nowrap text-capitalize"
        >
          <b-badge
            :variant="statusVariant(props.row.status)"
            class="font-14"
          >
            {{ statusTxt(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: Balance -->
        <span v-else-if="props.column.field === 'amount'">
          <span
            :class="amountColor(props.row.operator)"
          >{{ `${props.row.operator}${currencyFormat(props.row.amount)}` }}</span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="total > pageLength"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1 footer-tab"
          :class="[{'justify-content-center':$device.mobile}, {'justify-content-between':!$device.mobile}]"
        >

          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span class="font-medium font-14">{{ total }} {{ $t('payment.AdsAccountHistoryTable.textHistoryTransaction') }}</span>
          </div>
          <div v-if="total > pageLength">
            <b-pagination
              :total-rows="total"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 mt-1 mt-md-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-history-placeholder
          :img="require('@/assets/images/common/no-data-table.svg')"
          class="my-5"
        />
      </div>
      <!-- /no account image -->
    </vue-good-table>
    <!-- /table -->
  </div>
</template>
<script>
import {
  BPagination,
  BBadge,
  BSpinner,
  BImg,
  BLink,
  BRow,
  BCol,
} from 'bootstrap-vue'

// libs & cons
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import { TRANSACTION_TYPE } from '@/constants'
import Calendar from '@/components/Calendar.vue'

// mixins
import tableAccountHistoryMixin from '@/mixins/tableAccountHistoryMixin.vue'
import { bus } from '@/plugins/bus'
import envMixin from '@/mixins/envMixin'
import NoHistoryPlaceholder from './NoHistoryPlaceholder.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
export default {
  components: {
    NoHistoryPlaceholder,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BImg,
    BLink,
    // components
    VueGoodTable,
    vSelect,
    Calendar,
  },
  mixins: [tableAccountHistoryMixin, envMixin],
  data() {
    return {
      placeholder: this.$t('payment.AdsAccountHistoryTable.placeholderSelectDate'),
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      columns: [
        {
          label: this.$t('payment.AdsAccountHistoryTable.transactionDateColumn'),
          field: 'createdAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.ad'),
          field: 'adsAccountId',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.AdsAccountHistoryTable.transactionTypeColumn'),
          field: 'type',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.AdsAccountHistoryTable.transferStatusColumn'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('balance.textAmount'),
          field: 'amount',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {

    ...mapGetters(['listAccountHistory', 'message', 'status', 'loading']),

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate || this.rangeDate.startDate,
      }
    },

    histories() {
      return this.listAccountHistory.content
    },
    total() {
      return this.listAccountHistory.paging.total || 0
    },
  },

  created() {
    this.fetchHistory()
    bus.$on('refresh-ads-table-history', () => {
      this.resetData()
    })
  },

  methods: {
    ...mapActions(['getHistory']),
    async fetchHistory() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: [
          TRANSACTION_TYPE.RECHARGE_ADS_ACCOUNT_BALANCE,
          TRANSACTION_TYPE.REFUND_ADS_ACCOUNT_BALANCE,
          TRANSACTION_TYPE.SYSTEM_WITHDRAW_DUE_TO_INACTIVE,
        ],
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...((this.rangeDate.startDate || this.rangeDate.endDate) && this.rangeDates),
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
      }
      await this.getHistory({
        type: 'ads',
        params,
      })
    },

    resetData() {
      this.currentPage = 1
      this.searchTerm = ''

      this.sortTerm = {
        field: '',
        type: '',
      }

      this.pageLength = 10
      this.statusSelected = {
        name: 'All status',
        value: '',
      }

      this.rangeDate = {
        startDate: null,
        endDate: null,
      }
      this.dateSelected = null

      this.fetchHistory()
    },
  },

}
</script>

<style lang='scss' scoped>
.filter-mobile{
  padding: 0 20px;
}

  .input-control{

    .input-height{
      height: 40px;
    }
  }

  .ads-account-history{
    padding: 0 16px;
  }

  .footer-tab{
    padding: 0 1rem 1rem;
  }
</style>
<style lang="scss">
  .input-control{
    .vs__dropdown-toggle{
      height: 40px;
    }
  }
</style>
