<template>
  <div
    class="banner-header-container"
    :class="[isHideBanner ? 'd-none' : 'd-block', link ?'cursor-pointer' : '']"
    :style="{backgroundImage: `url(${img})`}"
    @click="goToDocsBanner"
  >
    <slot name="content" />
  </div>
</template>

<script>

export default {

  props: {
    img: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isHideBanner: false,
    }
  },

  mounted() {
    this.isHideBanner = JSON.parse(localStorage.getItem('isHideBanner'))
  },

  methods: {
    handleHideBanner() {
      this.isHideBanner = !this.isHideBanner
      localStorage.setItem('isHideBanner', JSON.stringify(this.isHideBanner))
    },

    goToDocsBanner() {
      if (this.link) {
        window.open(this.link)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-header-container {
  height: 270px;
  width: 100%;

  border-radius: var(--border-radius-2xl);
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 1rem;

  .btn-join-partnero {
    width: max-content;
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-background {
      text-align: center;

      .title-ecomdy-affiliate {
        margin-bottom: 1rem;
        font-size: 32px;
        color: #fff;

        .text-opacity{
          color: rgba(255, 255, 255, 0.70)
        }

        @media (max-width: 992px) {
          font-size: 25px;
        }

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .btn-controller {

      .btn-learn-more {
        padding: 12px 16px;
        border-radius: var(--btn-border-radius-base);
        border: 1px solid #FFF;
        width: 145px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          line-height: 20px;
          font-size: 20px;
          color: #fff;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: start;
      }
    }

    @media (max-width: 768px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 575px) {
    height: 250px;
  }
}
</style>
