<template>
  <div>
    <div class="balance-Overview bg-white">
      <div class="row w-100 m-0">
        <div class="w-100 d-lg-flex">
          <div class="left-content">
            <div class="available-balance px-0">
              <div class="title-content">
                <span
                  class="text-placeholder font-12 text-uppercase"
                >{{ $t('balance.textBalancePlatform',{platformName: isEmediaPlatform ? userName : platformName}) }}</span>
                <div class="text-content">
                  <b-spinner
                    v-if="loading"
                    variant="secondary"
                    small
                  />
                  <p
                    v-else
                    class="money font-medium"
                  >
                    ${{ userBalance }} USD
                  </p>
                </div>
              </div>
            </div>
            <div class="btn-card p-0 font-medium text-center">
              <btn-loading
                v-if="refundAutoMode"
                class="btn-submit-outline"
                variant-convert="btn-submit-outline"
                pill
                @click="handleAutoRefund"
              >
                {{ $t('payment.textRefund') }}
              </btn-loading>
              <!-- btn refund SBH -->
              <btn-loading
                v-if="refundMode"
                variant-convert="outline-secondary"
                class="font-14"
                style="padding: 8px 20px; margin-right: 1rem;"
                pill
                @click="$refs['refund-modal'].showModal()"
              >
                {{ $t('payment.textRefund') }}
              </btn-loading>
              <!-- btn refund SBH -->
              <btn-loading
                id="trigger-add-balance-ecomdy"
                variant-convert="btn-submit"
                class="btn-add-fund"
                pill
                @click="addBalance"
              >
                {{ $t('payment.textAddBalance') }}
              </btn-loading>
            </div>
          </div>
          <div class="d-flex pl-0 pr-0 pl-lg-2">
            <hr class="d-none d-lg-block divider">
            <div class="w-100">
              <p class="text-placeholder mb-0">
                {{ $t('payment.contentOrChooseAnther') }}
              </p>
              <div class="d-flex align-items-center flex-wrap">
                <router-link
                  v-if="hasCreditCard"
                  class="cursor-pointer border-content-payment text-capitalize"
                  to="/payment/add-fund?tab=creditcard"
                >
                  <div id="trigger-credit-card">
                    <div
                      id="trigger-credit-card"
                      style="padding: 2px 6px;"
                    >
                      <b-img
                        alt="card"
                        :height="43"
                        :src="require('@/assets/images/pages/payment/ic-stripe.png')"
                      />
                    </div>
                  </div>
                </router-link>
                <router-link
                  v-for="(payment, index) in listPaymentFiltered"
                  :key="index"
                  class="cursor-pointer border-content-payment text-capitalize"
                  :to="payment.url"
                >
                  <div
                    :id="`trigger-${payment.id}`"
                    class="d-flex align-items-center content-btn"
                  >
                    <b-img
                      fluid
                      alt="card"
                      class="icon-payment"
                      :src="payment.img"
                    />
                    <p class="font-bold text-card mb-0">
                      <span> {{ payment.name }} </span>
                      <span
                        v-if="payment.id === 'payoneer-ticket' && hasPayoneer"
                        class="mb-0"
                      >
                        Ticket
                      </span>
                      <!-- v-if="checkShowText(payment.name)" -->
                      <span
                        v-if="false"
                        id="text-recommended"
                        class="text-recommended"
                        :class="styleText(payment.name)"
                      >{{ $t('payment.textRecommended') }}</span>
                    </p>
                  </div>
                </router-link>
                <div
                  v-if="isEmediaPlatform"
                  class="cursor-pointer border-content-payment text-capitalize"
                  @click="nationalBanksUrl"
                >
                  <div
                    id="trigger-national-banks"
                    class="d-flex align-items-center content-btn"
                  >
                    <b-img
                      fluid
                      alt="card"
                      class="icon-payment"
                      :src="require('@/assets/images/pages/payment/ic-local-bank.png')"
                    />
                    <p class="font-bold text-card mb-0">
                      National Banks
                    </p>
                    <feather-icon
                      v-b-tooltip.top.v-light="'Click here if you want to top up via MAD currency'"
                      icon="InfoIcon"
                      size="12"
                      style="margin-top: -16px; margin-left: 4px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <refund-modal ref="refund-modal" />

    <popup-auto-refund
      :show-side-bar.sync="isShowAutoRefundPopup"
      :list-used-payment-methods="listUsedPaymentMethods"
      @handelNextRequest="handelNextRequest"
    />

    <modal-confirm-auto-refund
      ref="modal-confirm-request-refund"
      :info-request-refund="infoRequestRefund"
      @submitRequestRefundSuccess="submitRequestRefundSuccess"
    />

    <modal-request-refund-success
      ref="modal-request-refund-success"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BImg, VBModal, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import _get from 'lodash/get'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import envMixin from '@/mixins/envMixin'
import billingMixin from '@/mixins/billingMixin'
import authMixin from '@/mixins/authMixin'
import _find from 'lodash/find'
import paymentMixin from '@/mixins/paymentMixin'
import { PAYMENT_METHOD } from '@/constants'
import { toastification } from '@core/mixins/toast'
import RefundModal from '@/views/payment/components/Modal/RefundModal.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import PopupAutoRefund from './PopupAutoRefund.vue'
import ModalConfirmAutoRefund from './ModalConfirmAutoRefund.vue'
import ModalRequestRefundSuccess from './ModalRequestRefundSuccess.vue'

const { mapGetters } = createNamespacedHelpers('auth')
const {
  mapGetters: mapGettersAdsAccount,
} = createNamespacedHelpers('adsAccount')
const {
  mapGetters: mapGettersPayment,
  mapActions: mapActionsPayment,
} = createNamespacedHelpers('payment')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BtnLoading,
    RefundModal,
    BImg,
    BSpinner,
    PopupAutoRefund,
    ModalConfirmAutoRefund,
    ModalRequestRefundSuccess,
  },
  mixins: [numberFormatMixin, billingMixin, paymentMixin, envMixin, toastification, authMixin],
  data() {
    return {
      isHover: false,
      isShowAutoRefundPopup: false,
      infoRequestRefund: {},
    }
  },

  computed: {
    ...mapGetters(['user']),
    ...mapGettersAdsAccount(['listAccount']),
    ...mapGettersPayment(['listCard', 'listLocalBank', 'listUsedPaymentMethods']),

    checkShowText() {
      return value => value === PAYMENT_METHOD.AIRWALLEX
    },

    styleText() {
      const colorText = {
        [PAYMENT_METHOD.AIRWALLEX]: 'text-airwallex',
        [PAYMENT_METHOD.TRANSFERWISE]: 'text-wise',
      }

      return methodName => colorText[methodName]
    },

    userBalance() {
      const amount = _get(this.user, ['data', 'balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },

    loading() {
      return _get(this.user, ['loading'], false)
    },

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    currentCreditCardInfo() {
      const obj = _find(this.listCard, { default: true })
      if (obj) {
        return obj
      }

      return null
    },

    listPaymentFiltered() {
      const listPaymentSelect = this.listPaymentMethod.filter(item => item.id !== 'creditcard')
      return this.prohibitedCountries
        ? listPaymentSelect.filter(item => item.name !== PAYMENT_METHOD.TAZAPAY)
        : listPaymentSelect
    },
  },

  created() {
    if (this.listCard.length === 0) {
      this.getListCard()
    }

    if (this.listLocalBank.length === 0 && this.hasLocalBank) {
      this.getListLocalBank()
    }
  },

  methods: {
    ...mapActionsPayment(['getListCard', 'getListLocalBank']),

    cardEdit() {
      this.$router.push('/payment/add-fund?tab=creditcard')
    },

    addBalance() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

    addACreditCard() {
      this.$router.push('payment/add-fund?tab=credit-card')
    },

    toggleHover(value) {
      this.isHover = value
    },

    handleAutoRefund() {
      this.isShowAutoRefundPopup = true
    },

    handelNextRequest(params) {
      this.infoRequestRefund = params
      this.$refs['modal-confirm-request-refund'].showModal()
    },

    submitRequestRefundSuccess() {
      this.isShowAutoRefundPopup = false
      this.$refs['modal-request-refund-success'].showModalRequestSuccess()
    },

    nationalBanksUrl() {
      window.open('https://wa.me/message/7YS4KG2OBXNXF1')
    },
  },
}
</script>
<style lang="scss" scoped>
.balance-Overview {
  border-radius: var(--border-radius-base);
  padding: 24px;

  .left-content {
    display: flex;
    flex-direction: column;

    .available-balance {
      margin-bottom: 30px;
    }

    .text-content {
      font-size: 20px;
      margin-top: 4px;
      width: max-content;

      p {
        margin: 0;
      }

      /* .icon-copy {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      } */
    }

    .btn-card {
      display: flex;
      justify-content: start;

      .btn-submit-outline {
        margin-right: 12px;
      }

      .btn-add-fund {
        width: max-content;
        padding: 12px 16px;
        margin-right: 1rem;
      }
    }
  }

  .right-content {
    width: 100%;
    padding: 0 24px;
    height: auto;

    .info-card {
      width: 285px;
      background-image: url("../../../assets/images/pages/payment/card-bg.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;

      .content-card {
        padding: 20.5px 25.5px 22px 26.5px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-content {
          display: flex;
          width: 100%;
          color: #fff;
          font-size: 24px;
          justify-content: space-between;
        }

        .bottom-card {
          display: flex;
          justify-content: space-between;

          .btn-card {
            align-self: end;
            display: flex;

            .btn-card-edit,
            .btn-add-card {
              background-color: #fff;
              border-radius: 50%;
              cursor: pointer;
              padding: 11.5px 11.5px;

              &:hover {
                background-color: #1ea6c3;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .balance-Overview {
    display: block;

    .row {
      margin: 0 auto;
    }

    .left-content {
      border: none;
      margin: 0 auto 2rem !important;
      padding: 0;

      .bottom-left-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .right-content {
      margin: 0 auto;
      padding: 0 1rem;

      .info-card {
        margin: 1rem auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .row {
    margin: 0 auto;
  }

  .left-content {
    justify-content: space-between;
    margin: 0 auto 2rem !important;
    padding: 0 !important;

    .top-left-content {
      display: block !important;
      text-align: center;

      .btn-card {
        justify-content: center;
      }
    }

    .bottom-left-content {
      display: block !important;
      padding: 2rem 0 !important;
      text-align: center;
    }

    .bottom-right {
      justify-content: center !important;
      padding: 1rem 0 !important;
    }

    .total-ads-account {
      margin-bottom: 2rem !important;
    }

    .bc-id,
    .available-balance {
      margin: 2rem 0 !important;
    }
  }
}

.money {
  margin-top: 6px;
  font-size: 24px;
  font-weight: 700;
  // color: #1db9c3;

  .type-money {
    font-size: 14px;
    font-weight: 500;
    color: #16213e;

    .tooltip-money {
      margin-top: -10px;
      margin-left: 10px;

      .tooltip-inner {
        color: #16213e;
        background: #fff;
      }
    }
  }
}

.border-content-payment {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--input-border-radius-base);
  padding: 0;
  margin: 16px 16px 0 0;

  .content-btn {
    padding: 12px 16px;
  }
}

.border-content-local-bank {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0;
  margin: 16px 16px 0 0;

  .content-btn {
    padding: 4px 0;
  }
}

.ic-creditcard {
  margin-right: 10px;
}

.icon-payment {
  height: 16px;
}

.divider {
  width: 1px;
  height: 100%;
  margin: 0 24px 0 0;
  background-color: #eee;
}

.top-left-content {
  min-width: 300px;
  padding: 0;

  @media (max-width: 500px) {
    min-width: 270px;
  }

  @media (min-width: 1614px) {
    min-width: 370px;
  }
}

.text-card {
  margin-left: 4px;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.text-recommended {
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  padding: 0 6px;
  border-radius: 6px;
  margin-left: 12px;

  &.text-airwallex {
    background: linear-gradient(90deg, #ff4f42 0.12%, #ff8e3c 100.23%);
  }

  &.text-wise {
    background: #00b9ff;
  }
}

.logo-payment {
  height: 42px;
}
</style>
